"use client";

import styles from "../home.module.css";
import { useRef } from "react";
import Slider from "react-slick";

import nextArrow from "../../../assets/images/next.svg";
import previousArrow from "../../../assets/images/pevious.svg";


export default function Testimonial() {
    const sliderReference = useRef();

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        nextArrow: <img src={nextArrow} alt="Next" />,

    };

    let testimonials = [

        {
            "name": "Buddha",
            "body": "To keep the body in good health is a duty we owe to ourselves, our families, and our communities. It is the foundation upon which all else is built."
        },
        {
            "name": "Ralph Waldo Emerson",
            "body": "The first wealth is health. It is the rich soil in which our dreams take root, the sturdy frame upon which our aspirations are built. Without it, life is but a fragile existence, devoid of true abundance."
        },
        {
            "name": "Virgil",
            "body": "The greatest wealth is health. For what good is material abundance without the vitality to enjoy it? Health is the cornerstone of a life truly lived, the treasure beyond measure."
        },
        {
            "name": "Dalai Lama",
            "body": "Health is the foundation of our existence, the cornerstone of happiness and fulfillment. It is the precious gift we must cherish above all else, nurturing it with care and gratitude."
        },
        {
            "name": "Thomas Carlyle",
            "body": "Without health, there is no happiness. An ailing body saps the spirit, dims the brightest joys. It is the precious jewel we must safeguard, the wellspring of our vitality and joy."
        },
        {
            "name": "Julie Bishop",
            "body": "Health is the key to unlocking our fullest potential, the wellspring from which all greatness flows. It is the bedrock upon which our dreams are built, the fuel that propels us toward our aspirations."
        },
        {
            "name": "Aristotle",
            "body": "The preservation of health is a duty. It is the noblest pursuit, the highest endeavor. For without health, there can be no true fulfillment, no lasting joy."
        },
        {
            "name": "Thomas Jefferson",
            "body": "The care of human life and happiness is the first and only legitimate object of good government. Without health, all else is meaningless. It is the foundation upon which society thrives."
        },
        {
            "name": "Maya Angelou",
            "body": "The idea is to die young as late as possible. Health is the heartbeat of life, the rhythm that sustains us through every trial and triumph. It is the essence of our vitality, the compass guiding us toward a life of purpose and fulfillment."
        }
    ]
    ;

    return (
        <div className={styles.testimonial_container}>
            <div className={styles.testimonial}>
                <div className={styles.testinomial_main_container}>
                    <div>
                        <Slider {...settings} ref={sliderReference}>
                            {testimonials.map((testimonial, index) => (
                                <div key={index} className={styles.testimonial_inside_box}>
                                    <p className={styles.testimonial_name}>{testimonial.name}</p>
                                    <p className={styles.testimonial_body}>{testimonial.body}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className={styles.n_p_container} style={{marginTop:"20px"}}>
                        <div className={styles.slider_container}
                            onClick={() => {
                                sliderReference.current.slickPrev();
                            }}
                        >
                            <img
                                src={previousArrow}
                                className={styles.np_img}
                                alt="Previous Button"
                            />
                        </div>

                        <div className={styles.slider_container
                        }
                            onClick={() => {
                                sliderReference.current.slickNext();
                            }}
                        >
                            <img
                                src={nextArrow}
                                className={styles.np_img}
                                alt="next Button"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
