
"use client"

import { useNavigate } from "react-router-dom";
import styles from "../home.module.css";
import CustomButton from "../../../components/buttons/custom_button";


export default function Offers() {
    const navigate = useNavigate();
    return (
        <div className={styles.offers}>
            <div className={styles.offer_box_one}>
                <div className={styles.offer_content_box}>
                    <h3 className={styles.offer_head}>SAVE 35% OFF</h3>
                    <h3 className={styles.offer_body}>High Quality Healthy Tea</h3>
                    <CustomButton text="Shop Now" callBack={() => {
                        navigate("/kanwari/#products")
                    }} dynamicStyle="banner_button" />
                </div>
            </div>
            <div className={styles.offer_box_two}>
                <div className={styles.offer_content_box}>
                    <h3 className={styles.offer_head}>100% Natural</h3>
                    <h3 className={styles.offer_body}>Keep Cheers Amazing Taste</h3>
                    <CustomButton text="Shop Now" callBack={() => {
                        navigate("/kanwari/#products")
                    }} dynamicStyle="banner_button"/>
                </div>
            </div>
        </div>
    )
}

