import darjeeling500gm from "../assets/images/thumnail/DarjlingFrontSide500gm.jpg";
import darjeeling250gm from "../assets/images/thumnail/DarjlingFrontSize250gm.jpg";

import greenTeaTulsiFrontSide100gm from "../assets/images/thumnail/GreenTeaFrontSide100gm.jpg";

import greenTeaFrontSide250gm from "../assets/images/thumnail/GreenTeaFrontSide250gm.jpg";

import greenTeaFrontSide500gm from "../assets/images/thumnail/GreenTeaFrontSide500gm.jpg";

import immuneTeaFrontSide from "../assets/images/thumnail/ImmuneTeaFrontSide.jpg";

import premiumChaiFrontSide from "../assets/images/thumnail/PremiumChaiFrontSide.jpg";

import sippyCalmFrontSide from "../assets/images/thumnail/SippyCalmFrontSide.jpg";


const thumnailTeaImages = {
    "kanwari-darjeeling-black-tea-250gm":darjeeling250gm,
    "kanwari-darjeeling-black-tea-500gm":darjeeling500gm,
    "kanwari-boosty-immune-green-tea-100gm":immuneTeaFrontSide,
    "kanwari-eliche-cardamom-black-tea-4-pouch":premiumChaiFrontSide,
    "tulsi-green-tea-100gm":greenTeaTulsiFrontSide100gm,
    "kanwari-green-tea-250gm":greenTeaFrontSide250gm,
    "kanwari-green-tea-500gm":greenTeaFrontSide500gm,
    "kanwari-sippy-calm-green-tea-100gm":sippyCalmFrontSide
}


export default thumnailTeaImages;