import styles from "../home.module.css";
import serviceOne from "../../../assets/images/services-01.webp";
import serviceTwo from "../../../assets/images/services-02.webp";
import serviceThree from "../../../assets/images/services-03.webp";
import serviceFour from "../../../assets/images/services-04.webp";
import serviceFive from "../../../assets/images/services-05-1.webp"
import serviceSix from "../../../assets/images/services-06.webp";
import teaImg from "../../../assets/images/file.webp";




export default function ProductQuality() {
    return (
        <div className={styles.product_quality}>
            <div className={styles.content_left}>
                <div className={styles.content_box_left}>
                    <img
                        src={serviceSix}
                        alt="Service img"
                        className={styles.serviceImg}
                    />
                    <h2>100% Natural Tea</h2>
                    <p>The primary ingredient in natural tea is typically tea leaves</p>
                </div>
                <div className={styles.content_box_left}>
                    <img
                        src={serviceFive}
                        alt="Service img"
                        className={styles.serviceImg}
                    />
                    <h2>Best Flavours</h2>
                    <p>Natural teas may also contain herbs, flowers, spices, or other botanical ingredients to add flavor</p>
                </div>
                <div className={styles.content_box_left}>
                    <img
                        src={serviceFour}
                        alt="Service img"
                        className={styles.serviceImg}
                    />
                    <h2>Finest Quality</h2>
                    <p>Some natural teas incorporate dried or dehydrated fruits, berries, or citrus</p>
                </div>
            </div>
            <div className={styles.content_middle}>
                <img src={teaImg}
                alt="Tea Pack"
                className={styles.teaImg}
                />
            </div>
            <div className={styles.content_right}>
                <div className={styles.content_box_right}>
                    <img
                        src={serviceThree}
                        alt="Service img"
                        className={styles.serviceImg}
                    />
                    <h2>Buyer's Pick</h2>
                    <p>While the term "natural" generally suggests that the ingredients are derived from natural sources</p>
                </div>
                <div className={styles.content_box_right}>
                    <img
                        src={serviceTwo}
                        alt="Service img"
                        className={styles.serviceImg}
                    />
                    <h2>Awesome Aroma</h2>
                    <p>Natural teas do not contain artificial colors, flavors, preservatives, or sweeteners</p>
                </div>
                <div className={styles.content_box_right}>
                    <img
                        src={serviceOne}
                        alt="Service img"
                        className={styles.serviceImg}
                    />
                    <h2>Proper Roasting</h2>
                    <p>Some natural teas may also be labeled as organic, indicating that the tea leaves and additional ingredients</p>
                </div>
            </div>
        </div>
    )

}


