
import styles from "../product.module.css";


export default function Description(params){
    return (
        <div className={styles.product_description}>
            <div className={styles.p_description}>
                <h1 className={styles.product_des}>Description</h1>
                <p className={styles.detail_description}>{params.product.description}</p>
            </div>
        </div>
    )
}
