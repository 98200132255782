import { useRef } from "react";
import BannerSliders from "./components/banner_slider";
import PopularDrink from "./components/popular_drink";
import ProductList from "./components/product_list";
import ProductQuality from "./components/product_quality";
import StoryBehind from "./components/story_behind";
import Testimonial from "./components/testinomial";
import Offers from "./components/offers";
import BrandBanner from "./components/brand_banner";
import SEO from "../../components/seo/seo";


export default function Home() {

    const ref = useRef();

    return (
        <>
            <SEO
                title="Kanwari"
                description="Discover the finest selection of premium green teas from around the world. Shop organic, matcha, sencha, and more. Elevate your tea experience with our curated blends and expert brewing tips"
            />
            <BannerSliders />
            <StoryBehind />
            <ProductQuality />
            <PopularDrink />
            <ProductList productRed={ref} />
            <Offers />
            <Testimonial />
            <BrandBanner />
        </>
    );
}