import { useParams } from 'react-router-dom';

import localDb from "../../database/products.json";
import { useEffect, useState } from 'react';
import ProductsPage from './components/product_page';
import Loading from '../../components/Loading/loading';



export default function Products() {
    const { id } = useParams();
    const [product, setProducts] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const getProductBySlug = (slug) => {
        setIsLoading(true)
        for (let i = 0; i < localDb.length; i++) {
            if (localDb[i].slug === slug) {
                setProducts(localDb[i]);
                setIsLoading(false);
                break;
            }
        }
    }

    // Scroll to Top

    useEffect(() => {
        const handleRouteChange = () => {
            window.scrollTo(0, 0);
        };

        handleRouteChange();

    }, [id]);

    useEffect(() => {
        getProductBySlug(id)
    }, [id]);

    return (
        <>
            {
                isLoading ? <Loading /> : <ProductsPage id={id} product={product} />
            }
        </>
    )
}