"use client"

import styles from "../product.module.css";
import starFilled from "../../../assets/images/starFilled.png";
import { useState } from "react";
import CustomButton from "../../../components/buttons/custom_button";
import Modal from "../../../components/modal/modal";

export default function ProductContent(params) {
    let rating = [starFilled, starFilled, starFilled, starFilled, starFilled];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    return (<div className={styles.product_content}>
        <h3 className={styles.product_title}>{params.product.title}</h3>
        <p className={styles.brief_description}>{params.product.breif_description}</p>
        <div className={styles.rating}>
            {
                rating?.map((item, index) => {
                    return (
                        <img
                            src={item}
                            alt="rating"
                            className={styles.rating_star}
                        />
                    );
                })
            }
        </div>
        <p className={styles.price}>₹ {params.product.price_range}</p>


        <hr className={styles.dividor} />

        <CustomButton text="Shop Now" callBack={() => {
            handleOpenModal()
        }} dynamicStyle="shop_button" />

        <hr className={styles.dividor} />

        <div className={styles.category}>
            <p className={styles.category_title}>Category: </p>
            <p className={styles.category_body}>Tea, Coffee, Green Tea
            </p>
        </div>
        <div className={styles.category}>
            <p className={styles.category_title}>Ingredient: </p>
            <p className={styles.category_body}>Green Tea
            </p>
        </div>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} product={params.product}/>

    </div>)
}