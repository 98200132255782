
import styles from "../home.module.css";
import storyImageCmsOne from "../../../assets/images/cms-img-01.webp"
import storyImageCmsTwo from "../../../assets/images/cms-img-02.webp"
import CustomButton from "../../../components/buttons/custom_button";



export default function StoryBehind() {
    return (
        <div className={styles.story_behind} id="about">
            <div className={styles.story_image}>
                <div className={styles.story_image_div}>
                    <img src={storyImageCmsOne}
                        className={styles.storyImageCmsOne}
                        alt="Picture of the author" />
                    <img src={storyImageCmsTwo}
                        className={styles.storyImageCmsTwo}
                        alt="Picture of the author" />
                </div>
            </div>
            <div className={styles.story_content}>
                <p className={styles.kick_start_head}>KICK START YOUR CELEBRATIONS</p>
                <p className={styles.green_tea_story}>The Story Behind Our Green Tea </p>
                <p className={styles.story_detail}>At Kanwari Food & Trading Company Our Endeavour Is To Provide Best Tea Products To The Consumers. We Serve The Consumers Under The Brand Name ”Kanwari”. We Have Dedicated Team Of Professionals Who Work Day-In & Day-Out To Bring The Best Possible Products For Our Customers At Affordable Prices.
                </p>
                <CustomButton text="Watch Now" callBack={() => {
                    window.open('https://www.youtube.com/watch?v=6Qglvd2tXUE', '_blank');
                }} dynamicStyle="story_button" />
            </div>
        </div>
    );
}
