"use client"

import styles from "../home.module.css";
import Slider from "react-slick";
import { useRef } from "react";
import next from "../../../assets/images/next.svg";
import previous from "../../../assets/images/pevious.svg";
import CustomButton from "../../../components/buttons/custom_button";
import { useNavigate } from "react-router-dom";


export default function BannerSliders() {

    const sliderReference = useRef();

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
    };


    const navigate = useNavigate();

    return (
        <div className={styles.banner_sliders}>
            <Slider {...settings} ref={sliderReference}
       
            >
                <div className={styles.slider_one} key={0}>
                    {/* <h3>Enjoy Only The Best<br />Green Tea</h3>
                    <CustomButton text="Shop Now" callBack={() => {
                        navigate("/kanwari/#products")
                    }} dynamicStyle="banner_button" /> */}
                </div>
                <div className={styles.slider_two} key={1}>
                    {/* <div className={styles.slider_two_content}>
                        <h3>Harvest Every Tea Grain<br />With Love</h3>
                        <CustomButton text="Shop Now" callBack={() => {
                            navigate("/kanwari/#products")
                        }} dynamicStyle="banner_button" />
                    </div> */}
                </div>

            </Slider>
            <div className={styles.next_prev_button}>
                <div className={styles.previous}
                    onClick={() => {
                        sliderReference.current.slickPrev();
                    }}
                >
                    <img
                        src={previous}
                        className={styles.icon_image}
                        alt="previous button"
                    />
                </div>
                <div className={styles.next}
                    onClick={() => {
                        sliderReference.current.slickNext();
                    }}
                >
                    <img
                        src={next}
                        className={styles.icon_image}
                        alt="next button"
                    />
                </div>
            </div>
        </div>
    )
}

