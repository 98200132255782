import './App.css'
import StripLabel from './components/strips/strip'
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom'
import Home from './app/home/home';
import Footer from './components/footer/footer';
import Navigation from './components/navigations/navigation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./button_global.css";
import Products from './app/products/products';

function App() {

  const AppRoutes = () => {
    return useRoutes([
      // {
      //   path: '/',
      //   element: <Navigate to="/kanwari" replace />,  // Redirect to /kanwari
      // },
      {
        path: '/',
        element: <Home />,
        children: [
          // Add nested routes here if any
        ],
      },
      {
        path: '/kanwari',
        element: <Home />,
        children: [
          // Add nested routes here if any
        ],
      },
      {
        path: '/kanwari/product/:id',
        element: <Products />,  // Add the product route
      },
      // Add other routes here
    ]);
  };

  return (
    <>
      <div className='main_page'>
        <BrowserRouter>
          <StripLabel />
          <Navigation />
          <AppRoutes />
          <Footer />
        </BrowserRouter>
      </div>
    </>
  )
}

export default App
