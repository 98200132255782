
import styles from "../home.module.css";
import Slider from "react-slick";
import next from "../../../assets/images/next.svg";
import previous from "../../../assets/images/pevious.svg";
import { useRef, useState } from "react";
import productData from "../../../database/products.json"
import Modal from "../../../components/modal/modal";
import ProductCards from "../../../components/Cards/product_cards";

export default function ProductList({ dontShow }) {
    
    const sliderReference = useRef();
    const [data, setData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1270,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 1147,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    // 1263

    return (
        <>
            <div className={styles.product_list} id="products" >
                <div className={styles.product_list_heading}>
                    <h1 >Our Products</h1>
                </div>
                <div className={styles.product_list_container}>
                    <Slider {...settings} ref={sliderReference}>

                        {
                            productData.map((item, index) => {
                                if (item.slug !== dontShow) {

                                    return (
                                        <>
                                            <ProductCards handleOpenModal={handleOpenModal} data={item} key={index} index={index} setData={setData} />
                                        </>
                                    )
                                }
                            })
                        }
                    </Slider>
                </div>
                <div className={styles.n_p_container}>
                    <div className={styles.slider_container}
                        onClick={() => {
                            sliderReference.current.slickPrev();
                        }}
                    >
                        <img
                            src={previous}
                            className={styles.np_img}
                            alt="Previous Button"
                        />
                    </div>

                    <div className={styles.slider_container
                    }
                        onClick={() => {
                            sliderReference.current.slickNext();
                        }}
                    >
                        <img
                            src={next}
                            className={styles.np_img}
                            alt="next Button"
                        />
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} product={data} />
        </>
    )
}
