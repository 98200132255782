"use client"


import styles from "../components.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../buttons/custom_button";
import thumnailTeaImages from "../../utils/import_thumb_nail_images";



export default function ProductCards(props) {

    // {title:"Genmaicha Healthy Tea", price:"230-300", rating:2, image:"productImg"}
    const navigate = useNavigate();

    const [showImageNumber, setShowImageNumber] = useState(0);


    let images = thumnailTeaImages[props.data.slug];


    return (
        <>
        <div className={styles.product_card} 
            onClick={() => {
                navigate(`/kanwari/product/${props.data.slug}`)
            }}
            >
            <div className={styles.product_thumbnail}>
                <div className={styles.image_container}>
                <img src={images}
                    width={300}
                    className={styles.product_image}
                    alt="Thumbnail Img"
                    />
                    </div>
                <h1 className={styles.product_title}>{props.data.title}</h1>
                <p className={styles.prince_range} >₹ {props.data.price_range}</p>
                <CustomButton text="Buy Now" callBack={(e) => {
                    props.setData(props.data)
                    props.handleOpenModal();
                    e.stopPropagation()
                }} dynamicStyle="buy_now_button" />
            </div>
        </div>
    
    </>
    );
}
