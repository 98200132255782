

import styles from '../components.module.css'; // Import your CSS file for modal styles
import close from "../../assets/images/close.png";
import amazon from "../../assets/images/amazon.webp";
import flipkart from "../../assets/images/flip.webp";

const Modal = ({ isOpen, onClose, product }) => {

    return (
        <>
            {isOpen && (
                <div className={styles.modalOverlay} onClick={onClose}>
                    <div className={styles.modal} onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <div className={styles.close_button_container}>
                            <img src={close} alt='close button' onClick={onClose} className={styles.close_button}  style={{cursor:"pointer"}}/>
                        </div>
                        <div className={styles.product_urls}>
                            <div className={styles.url_container}>
                                <img src={flipkart}
                                    className={styles.flipkartLogo}
                                />
                                <div className={styles.price_button}>
                                    <h3 className={styles.price}>₹ {product?.flipkart_price}</h3>
                                    <div className={styles.shop_bt}
                                        onClick={() => {
                                            window.open(product?.flipkart, "_blank")
                                        }}
                                    >
                                        <p>Click Here</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.url_container}>
                                <img src={amazon}
                                    className={styles.amazonLogo}
                                />
                                <div className={styles.price_button}>
                                    <h3 className={styles.price}>₹ {product?.amazon_price}</h3>
                                    <div className={styles.shop_bt}

                                        onClick={() => {
                                            window.open(product?.amazon, "_blank")
                                        }}>
                                        <p>Click Here</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;