"use client"

import styles from "../home.module.css";
import kanwariOne from "../../../assets/images/kanwari01.webp"
import kanwariTwo from "../../../assets/images/kanwari02.webp"
import kanwariThree from "../../../assets/images/kanwari03.webp"
import kanwariFour from "../../../assets/images/kanwari04.webp"
import kanwariSeven from "../../../assets/images/kanwari07.webp"
import kanwariEight from "../../../assets/images/kanwari08.webp"
import kanwariNine from "../../../assets/images/kanwari09.webp"
import kanwariTen from "../../../assets/images/kanwari10.webp"
import nextArrow from "../../../assets/images/next.svg";
import previousArrow from "../../../assets/images/pevious.svg";
import { useRef } from "react";
import Slider from "react-slick";



export default function BrandBanner(){

    const sliderReference = useRef();



    let images_ban = [kanwariOne, kanwariTwo, kanwariThree, kanwariFour, kanwariSeven, kanwariEight, kanwariNine, kanwariTen];

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className={styles.brand_banners}>
            <Slider {...settings} ref={sliderReference}> 

            {
                images_ban.map((item,index) => {
                    return (
                        <div className={styles.kanwari_image_container}>
                                              <img src={item}
                   
                        key={index}
                        className={styles.kanwari_images}
                        alt="Kanwari imgs"
                        />
                        </div>
                    );
                })
                
            }
            </Slider>
            <div className={styles.n_p_container}>
                        <div className={styles.slider_container}
                            onClick={() => {
                                sliderReference.current.slickPrev();
                            }}
                        >
                            <img
                                src={previousArrow}
                                className={styles.np_img}
                                alt="Previous Button"
                            />
                        </div>

                        <div className={styles.slider_container
                        }
                            onClick={() => {
                                sliderReference.current.slickNext();
                            }}
                        >
                            <img
                                src={nextArrow}
                                className={styles.np_img}
                                alt="next Button"
                            />
                        </div>
                    </div>
        </div>
    )
}