"use client";

import styles from "../components.module.css";
import closeImg from "../../assets/images/close.png";
import { useNavigate } from "react-router-dom";

export default function MobileNavigation({ open, setClose }) {
    const navigate = useNavigate();
    return (
        <div className={`${styles.mobile_navigation_drawer} ${open ? styles.open_drawer_mobile : ''}`}>
            <div className={styles.cross_navigation_icon}>
                <img
                    src={closeImg}
                    onClick={() => {
                        setClose(false);
                    }}
                    className={styles.close_image}
                />
            </div>
            <div className={styles.mobile_navigation_drawer_links}>
                <li
                    onClick={() => {
                        setClose(false);
                        navigate("/kanwari");
                    }}
                >Home</li>
                <li
                    onClick={() => {
                        navigate("/kanwari#products");
                        setClose(false);
                    }}
                >Our Products</li>
                <li
                    onClick={() => {
                        navigate("/kanwari#about");
                        setClose(false);
                    }}
                >About Us</li>
                <li
                    onClick={() => {
                        navigate("/kanwari#contact-us");
                        setClose(false);
                    }}
                >Contact Us</li>
            </div>
        </div>
    );
}