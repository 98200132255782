
import logo from "../../assets/images/logo_kanwari.png"
import searchImg from "../../assets/images/search-light-image.svg"
import menuImg from "../../assets/images/menu.png";

import styles from "../components.module.css";
import MobileNavigation from './mobile_navigation';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import SearchProduct from "../search_products/search_products";


export default function Navigation() {

    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openSearchDrawer, setSeachOpenDrawer] = useState(false);

    return (
        <>
            <SearchProduct open={openSearchDrawer} setClose={() => { setSeachOpenDrawer(false) }} />
            <div className={styles.navigation}>
                <div className={styles.nav_image_container}>
                    <img
                        src={logo}
                        className={styles.logoImg}
                        alt="Picture of the author"
                        onClick={() => {
                            navigate("/kanwari");
                        }}
                    />
                </div>
                <div className={styles.navigation_links}>
                    <li onClick={() => {
                        navigate("/kanwari");
                    }}>Home</li>
                    <li
                        onClick={() => {
                            navigate("/kanwari#products");
                        }}
                    >Our Products</li>
                    <li
                        onClick={() => {
                            navigate("/kanwari/#about");
                        }}
                    >About Us</li>
                    <li
                        onClick={() => {
                            navigate("/kanwari#contact-us");
                        }}
                    >Contact Us</li>
                </div>
                <div className={styles.navigation_search}>
                    <img
                        src={menuImg}
                        onClick={() => {
                            setOpenDrawer(true);
                        }}
                        className={styles.menuImg}
                        alt="Picture of the author"
                    />
                    <div className={styles.search_box}
                        onClick={() => {
                            setSeachOpenDrawer(true);
                        }}
                    >
                        <p>Search..</p>
                        <img
                            src={searchImg}

                            className={styles.searchImg}
                            alt="Picture of the author"
                        />
                    </div>
                </div>

            </div>
            <MobileNavigation open={openDrawer} setClose={setOpenDrawer} />
        </>
    );
}


